import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { auth } from "../../firebase/firebase-configuration";
import { useAuth } from "../../context/AuthContext";
import { useUnreadMessages } from "../../context/UnreadMessagesProvider";
import { BsChatTextFill } from "react-icons/bs";
import defaultProfileImage from "../../assets/defaultProfileImage.jpg";
import { useTranslation } from "react-i18next";
import { IoIosMenu } from "react-icons/io";

export const Header: React.FC = () => {
  const { user, signedInUserData, isVerified, onboardingStep } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { unreadMessages, clearUnreadMessages } = useUnreadMessages();
  const { t: translate } = useTranslation("headerFooter");

  const showHeader =
    user && signedInUserData?.interests && signedInUserData?.story;

  useEffect(() => {
    if (user) {
      setIsDropdownOpen(false);
    }
  }, [user]);

  const handleOptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpen(false);
    setIsUserDropdownOpen(false);
  };

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const getLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return `font-karla tracking-tighter ${
      isActive ? "underline text-[#c77f6b]" : "text-gray-900 hover:text-accent"
    }`;
  };

  // Conditionally render based on onboardingStep
  if (onboardingStep) {
    return (
      <nav className="w-full py-4 px-2 border-b border-gray-200 shadow-sm">
        <div className="flex justify-between items-center px-6 md:px-10">
          <div className="font-newsreader text-3xl md:text-4xl">
            {/* Disable navigation if onboardingStep is active */}
            <a
              href="#"
              onClick={(e) => e.preventDefault()}
              className="text-accent font-newsreader-light cursor-not-allowed"
            >
              TheLonelyNoMore
            </a>
          </div>
          <div className="hidden lg:flex space-x-4 font-karla items-center">
            <NavLink to="/auth" className="text-gray-900 hover:text-accent">
              {translate("header.signInSignUp")}
            </NavLink>
          </div>
        </div>
      </nav>
    );
  }

  return (
    <nav className="w-full py-4 px-2 border-b border-gray-200 shadow-sm">
      <div className="flex justify-between items-center px-6 md:px-10">
        <div className="font-newsreader text-3xl md:text-4xl">
          <NavLink
            to="/"
            className="text-accent hover:text-primary font-newsreader-light"
          >
            TheLonelyNoMore
          </NavLink>
        </div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex space-x-4 font-karla items-center">
          {user && isVerified && showHeader ? (
            <>
              {/* <NavLink to="/my-story" className={getLinkClassName}>
                {translate("header.myStory")}
              </NavLink> */}
              <NavLink to="/pricing" className={getLinkClassName}>
                {translate("header.pricing")}
              </NavLink>
              <NavLink to="/how-it-works" className={getLinkClassName}>
                {translate("header.howItWorks")}
              </NavLink>
              <NavLink to="/profile" className={getLinkClassName}>
                {translate("header.profile")}
              </NavLink>
              <NavLink to="/community" className={getLinkClassName}>
                {translate("header.community")}
              </NavLink>
              <NavLink
                to="/messages"
                className={getLinkClassName}
                onClick={clearUnreadMessages}
              >
                <div className="relative">
                  <BsChatTextFill
                    size={30}
                    className="text-primary hover:text-accent"
                  />
                  {unreadMessages && (
                    <span className="absolute top-0 right-0 block w-3 h-3 bg-red-500 rounded-full"></span>
                  )}
                </div>
              </NavLink>
              <div className="relative">
                <img
                  src={
                    signedInUserData?.croppedPhotoURL ||
                    signedInUserData?.photoURL ||
                    defaultProfileImage
                  }
                  alt="User"
                  className="w-9 h-9 rounded-full object-cover cursor-pointer shadow border"
                  onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                  loading="lazy"
                />

                {isUserDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    <NavLink
                      to="/settings/user-info"
                      onClick={handleOptionClick}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      {translate("header.settings")}
                    </NavLink>

                    <NavLink
                      to="/favorites"
                      onClick={handleOptionClick}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      {translate("header.favorites")}
                    </NavLink>
                    <button
                      onClick={handleSignOut}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      {translate("header.signOut")}
                    </button>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              {/* <NavLink to="/my-story" className={getLinkClassName}>
                {translate("header.myStory")}
              </NavLink> */}
              <NavLink to="/pricing" className={getLinkClassName}>
                {translate("header.pricing")}
              </NavLink>
              <NavLink to="/how-it-works" className={getLinkClassName}>
                {translate("header.howItWorks")}
              </NavLink>
              <NavLink to="/community" className={getLinkClassName}>
                {translate("header.community")}
              </NavLink>
              <NavLink to="/auth" className={getLinkClassName}>
                {translate("header.signInSignUp")}
              </NavLink>
            </>
          )}
        </div>

        {/* Hamburger Menu for Mobile */}
        <div className="lg:hidden">
          <div className="flex items-center">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="p-2 focus:outline-none"
              aria-label="Toggle Menu"
            >
              <IoIosMenu size={35} />
            </button>
          </div>

          {isDropdownOpen && (
            <div className="absolute right-0 w-48 bg-white rounded-md shadow-lg z-20 border border-gray-200">
              {user && isVerified && showHeader ? (
                <>
                  {/* <NavLink
                    to="/my-story"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.myStory")}
                  </NavLink> */}
                  <NavLink
                    to="/pricing"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.pricing")}
                  </NavLink>
                  <NavLink
                    to="/how-it-works"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.howItWorks")}
                  </NavLink>

                  <NavLink
                    to="/community"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.community")}
                  </NavLink>
                  <NavLink
                    to="/profile"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.profile")}
                  </NavLink>
                  <NavLink
                    to="/favorites"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.favorites")}
                  </NavLink>
                  <NavLink
                    to="/settings/user-info"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.settings")}
                  </NavLink>
                  <button
                    onClick={handleSignOut}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.signOut")}
                  </button>
                </>
              ) : (
                <>
                  <NavLink
                    to="/auth"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.signInSignUp")}
                  </NavLink>
                  {/* <NavLink
                    to="/my-story"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.myStory")}
                  </NavLink> */}
                  <NavLink
                    to="/pricing"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.pricing")}
                  </NavLink>

                  <NavLink
                    to="/how-it-works"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.howItWorks")}
                  </NavLink>

                  <NavLink
                    to="/community"
                    onClick={handleOptionClick}
                    className="block w-full px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    {translate("header.community")}
                  </NavLink>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};
